import React from "react"
import { Link } from "gatsby"
import { Card } from "react-bootstrap"

const PostsListCard = ({ frontmatter, fields, excerpt }) => {
  const title = frontmatter.title || fields.slug

  return (
    <Card className="mb-4">
      <Card.Body>
        <Link to={`/${fields.slug}/`}>
        <h2 className="card-title">{title}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: frontmatter.description || excerpt,
          }}
        />
        </Link>
      </Card.Body>
      <Card.Footer className="text-muted">
        Frissítve: {frontmatter.date}
      </Card.Footer>
    </Card>
  )
}

export default PostsListCard
